:root {
  --black-2: #231f20;
  --black: #212121;
  --grey-lightest: whitesmoke;
  --light-brown: #f3e7d7;
  --tan: #d3bba8;
  --white: white;
  --dim-grey: #616161;
  --silver: #bdbdbd;
  --secondary-brown: #ae8a79;
  --grey-light: #e0e0e0;
  --grey: #929292;
  --light-beige: #f6f2ec;
}

body {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  color: var(--black-2);
  letter-spacing: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
  font-size: 38px;
  font-weight: 300;
  line-height: 44px;
}

h2 {
  color: var(--black-2);
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
}

h3 {
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
}

h4 {
  color: var(--black-2);
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
}

p {
  color: var(--black-2);
  letter-spacing: 0.25px;
  margin-bottom: 10px;
  font-family: Lora, sans-serif;
  line-height: 22px;
}

a {
  color: #9c6348;
  letter-spacing: 0.5px;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  text-decoration: none;
}

.body {
  height: 2000px;
  font-family: Raleway, sans-serif;
}

.full-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.close-btn img {
  z-index: 999;
}

.social-link {
  z-index: 5;
  width: 36px;
  height: 36px;
  border-radius: 200px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.subtitle {
  color: var(--black);
  letter-spacing: 1.5px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5em;
}

.subtitle.white {
  color: var(--grey-lightest);
}

.subtitle.beige {
  color: var(--light-brown);
  margin-top: -10px;
  margin-bottom: 10px;
}

.subtitle.small {
  color: var(--light-brown);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 800;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  position: relative;
}

.container.top-align {
  align-items: flex-start;
}

.column-6 {
  width: 47.8%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.h1 {
  color: #222;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
}

.h1.hero-h1 {
  color: var(--black);
  margin-bottom: 10px;
}

.section-base {
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 120px;
  font-family: Roboto, sans-serif;
  display: flex;
}

.section-base.dark-bg {
  background-color: #1f2324;
  background-image: url("/images/lines-21x-1.svg");
  background-position: 0 0;
  background-size: auto;
  position: relative;
}

.section-base.dark-bg.no-lines {
  background-image: none;
  background-position: 0 0;
  background-size: auto;
}

.section-base.dark-bg._0-120 {
  padding-top: 0;
}

.section-base.hero {
  padding-top: 30px;
}

.section-base._1,
.section-base._2 {
  background-color: #000;
  padding-top: 60px;
  padding-bottom: 40px;
  display: block;
  position: relative;
}

.section-base._3 {
  background-color: #000;
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative;
}

.section-base.services {
  padding-bottom: 60px;
}

.section-base.black-small {
  background-color: #000;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
}

.section-base.no-space {
  padding-top: 0;
  padding-bottom: 0;
}

.section-base._60-0 {
  padding-top: 60px;
  padding-bottom: 0;
}

.column-12 {
  width: 100%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.h2 {
  color: var(--black);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 25px;
  font-family: Raleway, sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.1em;
}

.h2.white {
  color: #fff;
}

.h2.white.small {
  font-size: 32px;
}

.column-3 {
  width: 22.8%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.column-3.col-3-mobile {
  margin-bottom: 30px;
}

.column-5 {
  width: 38.4%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.column-7 {
  width: 57.2%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.link-footer {
  color: var(--light-brown);
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  text-decoration: none;
}

.link-footer:hover {
  color: var(--tan);
  text-decoration: underline;
}

.link-footer.team-link {
  color: var(--white);
  margin-top: 10px;
  font-size: 16px;
}

.footer-wrapper {
  width: 100%;
  margin-top: 60px;
  display: flex;
}

.footer-text-wrapper {
  width: 100%;
  border-top: 1px solid var(--dim-grey);
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.copyright {
  width: 100%;
  color: var(--silver);
  letter-spacing: 0.5px;
}

.footer-heading {
  width: 100%;
  color: var(--light-brown);
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 800;
  line-height: 46px;
}

.footer {
  background-color: #1f2324;
}

.footer-nav {
  text-align: right;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  display: flex;
}

.footer-nav.logo {
  margin-top: 7px;
}

.social-block {
  margin-top: 30px;
}

.social-block.footer {
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
  margin-left: 40px;
  display: flex;
}

.social-img {
  height: 22px;
  margin-right: 20px;
}

.column-2 {
  width: 14.4%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.column-4 {
  width: 31.1%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.column-8 {
  width: 64.5%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.faq-question-wrap {
  border-top: 2px solid #f3e9db;
  border-bottom: 2px solid #f3e9db;
  margin-top: -2px;
  margin-bottom: 0;
}

.quote {
  color: var(--tan);
  letter-spacing: 1px;
  margin-top: 20px;
  font-family: Raleway, sans-serif;
  font-size: 34px;
  font-style: italic;
  font-weight: 800;
  line-height: 48px;
}

.column-9 {
  width: 72.8%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.column-1 {
  width: 7.2%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.column-11 {
  width: 88.4%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.column-10 {
  width: 81.2%;
  margin-left: 1.1%;
  margin-right: 1.1%;
}

.melo-header-img {
  z-index: 99;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: 50% 32%;
  border-radius: 2px;
  position: relative;
}

.services-wrapper {
  width: 100%;
  display: flex;
}

.team-wrapper {
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.text-head-area {
  width: 60%;
}

.text-head-area.large {
  width: 90%;
}

.text-head-area._100 {
  width: 100%;
}

.paragraph {
  color: var(--black);
  font-size: 16px;
  line-height: 26px;
}

.paragraph.white {
  color: var(--white);
}

.paragraph.white.raleway {
  letter-spacing: 0.5px;
  margin-top: -5px;
  font-family: Raleway, sans-serif;
}

.quote-area {
  margin-bottom: 40px;
}

.quote-author {
  color: var(--white);
  text-align: right;
  letter-spacing: 1px;
  margin-top: 10px;
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

.story-img {
  padding-left: 60px;
}

.book-block {
  background-color: var(--light-brown);
  margin-top: -190px;
  padding-top: 110px;
  padding-bottom: 50px;
}

.book-block.silver {
  background-color: var(--grey-lightest);
}

.button-block {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.button-block.center {
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.services-block {
  position: relative;
  height: 95%;
  border: 2px solid var(--black);
  border-radius: 2px;
  margin-top: 40px;
  margin-left: 0;
  margin-right: 20px;
  padding: 25px 25px 15px;
  transition: all 0.2s;
}

.services-block:hover {
  background-color: var(--black-2);
  color: var(--white);
  transform: none;
}

.services-img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 2px;
}

.packages-img {
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.services-text {
  letter-spacing: 0.25px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
  font-size: 28px;
  line-height: 46px;
}

.services-text.white {
  color: var(--white);
  font-weight: 400;
}

.services-text.service-only {
  line-height: 36px;
}

.services-link {
  display: flex;
}

.services-link-text {
  margin-right: 10px;
}

.services-link-text.white {
  color: var(--white);
}

.team-block {
  height: 100%;
  border: 2px solid var(--black);
  filter: grayscale();
  background-color: #000;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
  padding: 25px 25px 20px;
  transition: all 0.2s;
  display: flex;
}

.team-block:hover {
  filter: none;
  transform: scale(1.025);
}

.team-member-img {
  width: 200px;
  filter: none;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 2px;
}

.team-member-img.top-40 {
  object-position: 50% 40%;
}

.team-member-img.top-30 {
  object-position: 50% 30%;
}

.white-arrow-img {
  filter: invert();
}

.social-buttons {
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
}

.social-link-img {
  width: 30px;
  margin-right: 10px;
}

.social-link-img.black {
  width: 22px;
  filter: invert();
  margin-bottom: 2px;
}

.social-link-img.black:hover {
  opacity: 0.5;
}

.social-link-img.contact-social {
  margin-left: 20px;
  padding-top: 15px;
}

.social-link-img.contact-social.small {
  width: 100%;
  height: 30px;
  margin-top: 12px;
  padding-top: 0;
  display: flex;
}

.context-texts {
  letter-spacing: 0.5px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.context-texts.hours {
  margin-bottom: 40px;
}

.text-span-2 {
  color: var(--secondary-brown);
}

.pointer {
  z-index: 20;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: -3%;
  bottom: auto;
  left: 0%;
  right: 4%;
  transform: rotate(45deg);
}

.dropdown-title {
  margin-bottom: 2px;
  font-size: 15px;
}

.navigation-right {
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  display: flex;
}

.dropdown {
  color: #000;
  font-size: 16px;
}

.navigation-left {
  align-items: center;
  display: flex;
}

.dropdown-toggle {
  color: var(--black);
  letter-spacing: 1.5px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  align-items: center;
  padding: 10px 8px 10px 14px;
  font-weight: 600;
  transition: all 0.2s;
  display: flex;
}

.dropdown-toggle:hover {
  border-bottom: 2px solid var(--secondary-brown);
}

.dropdown-pointer {
  position: relative;
}

.nav-arrow {
  width: 20px;
  opacity: 0.5;
  display: inline-block;
}

.dropdown-wrapper {
  min-width: 140px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px -19px 35px rgba(32, 53, 90, 0.08), 11px 11px 30px -10px rgba(32, 53, 90, 0.15);
}

.dropdown-wrapper.w--open {
  max-height: 100vh;
  min-width: 300px;
  background-color: #fff;
  border-radius: 15px;
  left: -97px;
  box-shadow: 0 50px 25px -50px rgba(32, 53, 90, 0.15), 8px 18px 60px 4px rgba(0, 0, 0, 0.13);
}

.brand-link-block {
  margin-right: 44px;
}

.logo-inner {
  width: 20px;
  height: 40px;
  opacity: 0.95;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 10%, rgba(255, 255, 255, 0));
  border: 0.5px solid #000;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 3px;
  right: 0;
  transform: rotate(15deg);
}

.nav-link-details {
  color: rgba(40, 51, 56, 0.6);
  white-space: normal;
  font-size: 14px;
}

.navigation-container {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.dropdown-link {
  z-index: 25;
  color: #283338;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  position: relative;
}

.dropdown-link:hover {
  background-color: var(--light-brown);
  color: #000;
}

.nav-content-wrap {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
  display: flex;
}

.nav-link-2 {
  color: var(--black);
  letter-spacing: 1.5px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s;
}

.nav-link-2:hover {
  border-bottom-color: var(--black);
}

.nav-link-2.w--current {
  border-bottom-color: var(--secondary-brown);
  color: var(--black);
}

.nav-link-2.show-on-mobile,
.nav-link-2.mobile {
  display: none;
}

.navigation-dropdown {
  min-width: 140px;
  background-color: rgba(221, 221, 221, 0);
  display: none;
}

.navigation-dropdown.w--open {
  max-height: 100vh;
  min-width: 320px;
  background-color: rgba(0, 0, 0, 0);
  padding-top: 10px;
  left: -100px;
}

.navigation {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  align-items: center;
  padding: 25px 3%;
  display: flex;
  position: relative;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.icon-wrap {
  width: 40px;
  height: 40px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-menu-2 {
  color: #283338;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  position: static;
}

.melo-logo {
  max-width: 230px;
}

.button-filled {
  width: auto;
  height: 45px;
  color: #fff;
  text-transform: uppercase;
  background-color: #333;
  border: 2px solid #333;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  font-family: Inconsolata, monospace;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 3px #d3d3d3;
}

.button-filled.allproducts-btn {
  box-shadow: none;
  letter-spacing: 5px;
  background-color: #263cc0;
  background-image: linear-gradient(148deg, #263cc0, #a30daf);
  border-style: none;
  border-radius: 2px;
  margin-right: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 1em;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 5%;
  left: auto;
  right: 0;
}

.button-filled.allproducts-btn.learn-more {
  height: 40px;
  border-style: solid;
  border-color: var(--black);
  background-color: var(--black);
  background-image: none;
  margin-right: 0;
  padding-left: 50px;
  padding-right: 50px;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  position: relative;
  bottom: auto;
  right: auto;
}

.button-filled.allproducts-btn.learn-more.outline {
  background-color: var(--white);
  color: var(--black);
}

.button-filled.allproducts-btn.learn-more.filled-new {
  width: 250px;
  margin-top: 25px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.button-filled.allproducts-btn.learn-more.outline-new {
  width: 250px;
  border-color: var(--white);
  background-color: var(--white);
  color: var(--black);
  margin-top: 25px;
  margin-bottom: 20px;
}

.button-filled.allproducts-btn.submit {
  height: 40px;
  border-style: solid;
  border-color: var(--black);
  background-color: var(--black);
  letter-spacing: 2px;
  background-image: none;
  margin-right: 0;
  padding-left: 50px;
  padding-right: 50px;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s;
  position: relative;
  bottom: auto;
  right: auto;
}

.button-filled.allproducts-btn.submit:hover {
  background-color: var(--white);
  color: var(--black);
}

.button-filled.allproducts-btn.white-outline {
  height: 40px;
  border: 1px solid var(--white);
  background-color: var(--black);
  background-image: none;
  margin-right: 0;
  padding-left: 50px;
  padding-right: 50px;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  position: relative;
  bottom: auto;
  right: auto;
}

.button-filled-text {
  z-index: 1;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: none;
  font-size: 16px;
  position: relative;
}

.button-filled-text.button-filled-text-mobile {
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
}

.button-filled-text.button-filled-text-mobile.mobile-white-text,
.button-filled-text.button-filled-text-mobile.black-text {
  font-size: 16px;
}

.button-filled-mask {
  width: 0;
  height: 200px;
  background-color: #fff;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  transform: rotate(31deg);
}

.button-filled-mask.black {
  background-color: var(--black);
}

.nav-social {
  margin-left: 30px;
  margin-right: 30px;
}

.social-img-2 {
  transition: all 0.2s;
}

.hair-img {
  border-radius: 2px;
}

.hair-img.profile {
  width: 100%;
  height: 410px;
  object-fit: cover;
}

.lottie-img {
  height: 50px;
  filter: invert();
  margin-top: -20px;
  margin-left: -8px;
}

.link-2,
.heading {
  color: var(--secondary-brown);
}

.footer-img {
  width: 100px;
}

.footer-address {
  display: flex;
}

.contact-form {
  margin-top: 20px;
}

.input-field {
  width: 40%;
  border: 2px solid var(--silver);
  color: var(--black);
  letter-spacing: 0.5px;
  flex: 1;
  margin-bottom: 20px;
  margin-right: 20px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
}

.input-field:focus {
  border-color: var(--black);
}

.input-block {
  display: flex;
}

.textarea {
  width: 96%;
  border: 2px solid var(--silver);
  color: var(--black);
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
}

.textarea:focus {
  border-color: var(--black);
}

.html-embed {
  filter: contrast(107%) saturate(0%);
  border: 2px solid #000;
  border-radius: 3px;
  margin-top: 60px;
  padding: 20px;
  overflow: hidden;
}

.html-embed.mobile {
  display: none;
}

.map-wrapper {
  height: 100%;
  margin-top: 30px;
  margin-left: 40px;
}

.image-2 {
  max-width: 560px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.footer-area-1 {
  margin-left: 20px;
}

.h3-white {
  color: var(--white);
  font-size: 24px;
}

.h3-white.more-space {
  margin-bottom: 20px;
}

.services-white-text {
  color: var(--tan);
  letter-spacing: 0.5px;
  margin-top: -5px;
  margin-bottom: 10px;
  font-family: Raleway, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 46px;
}

.services-white-text.small {
  margin-bottom: 15px;
  margin-left: 20px;
  line-height: 24px;
}

.services-white-text.small.smaller {
  margin-top: -15px;
}

.service-text-block {
  border-top: 1px solid var(--dim-grey);
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  display: flex;
}

.service-text-area {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.white-span {
  color: var(--white);
}

.arrow-up {
  width: 50px;
  display: none;
  position: absolute;
  top: -10%;
  bottom: auto;
  left: 5%;
  right: auto;
}

.arrow-up.arrow-2 {
  top: -22%;
  left: 37%;
}

.arrow-up.arrow-3 {
  top: -23%;
  left: 67%;
}

.close {
  width: 31px;
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 10px;
}

.anchor-area {
  padding-top: 40px;
  position: relative;
}

.new-link {
  color: var(--secondary-brown);
  letter-spacing: 0.7px;
  line-height: 34px;
  text-decoration: underline;
}

.new-link:hover {
  color: var(--tan);
}

.social-link-team-wrapper {
  margin-top: -35px;
}

.teste {
  max-width: 560px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.sarah-img {
  padding-left: 0;
  padding-right: 40px;
}

.gallery-container {
  background-color: var(--grey-lightest);
  padding-left: 0;
  padding-right: 0;
}

.carousel {
  display: flex;
}

.card-melo {
  width: 326px;
  height: 320px;
  margin-right: 20px;
}

.image-card {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.line {
  width: 95vw;
  height: 1px;
  background-color: #141414;
}

.line._2 {
  margin-top: 40px;
}

.social-contact {
  margin-bottom: 0;
  display: flex;
}

.team-page-profile-wrapper {
  width: 100%;
  height: 100%;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 140px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.profile-img {
  height: 100%;
  padding-left: 0;
  padding-right: 40px;
}

.arrow-mobile {
  display: none;
}

.instagram-embed {
  width: 100%;
}

.white-block {
  z-index: 999999;
  width: 100%;
  height: 80px;
  background-color: var(--white);
  margin-top: -60px;
  position: relative;
}

.social-link-text {
  color: var(--tan);
  align-items: center;
  margin-left: 10px;
  font-size: 19px;
  display: flex;
}

.white-small {
  color: var(--white);
}

.white-small.unbold {
  font-weight: 400;
}

.join {
  align-items: center;
  margin-top: 60px;
  margin-bottom: -40px;
  margin-left: 10px;
  display: flex;
}

.success-message {
  background-color: var(--white);
  color: var(--black);
  border: 2px solid #000;
  border-radius: 2px;
  font-family: Raleway, sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 1122px) {
  .subtitle.beige,
  .subtitle.small {
    font-size: 14px;
  }

  .h1 {
    font-size: 48px;
    line-height: 1.1em;
  }

  .h1.hero-h1 {
    font-size: 30px;
  }

  .section-base {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .section-base.hero {
    padding-top: 10px;
  }

  .h2 {
    font-size: 32px;
  }

  .column-3.col-3-mobile {
    width: 31.1%;
  }

  .footer-text-wrapper {
    padding-left: 1.1%;
    padding-right: 1.1%;
  }

  .footer-heading {
    width: 80%;
  }

  .footer-nav.services-nav {
    align-items: flex-end;
  }

  .text-head-area {
    width: 100%;
  }

  .book-block {
    padding-top: 130px;
    padding-bottom: 30px;
  }

  .services-block {
    height: 95%;
    margin-right: 15px;
    padding: 15px 15px 0;
  }

  .services-img {
    height: 170px;
  }

  .services-text {
    font-size: 24px;
  }

  .services-text.service-only {
    line-height: 30px;
  }

  .team-member-img {
    height: 170px;
  }

  .navigation-left {
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .dropdown-toggle {
    font-size: 13px;
  }

  .brand-link-block {
    margin-bottom: 10px;
    margin-right: auto;
  }

  .menu-button {
    width: 44px;
    height: 44px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    padding: 7px 0 0;
    font-size: 32px;
  }

  .menu-button:hover {
    background-color: #fff;
  }

  .menu-button.w--open {
    background-color: #000;
    position: fixed;
  }

  .navigation-container {
    flex-direction: column;
  }

  .nav-link-2 {
    font-size: 14px;
  }

  .melo-logo {
    width: 200px;
    max-width: none;
  }

  .button-filled {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .button-filled.allproducts-btn {
    box-shadow: none;
    background-color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    border-style: none;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    bottom: 0%;
  }

  .button-filled.allproducts-btn.learn-more {
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    top: auto;
    bottom: 0;
    right: 0%;
  }

  .button-filled.allproducts-btn.learn-more.outline,
  .button-filled.allproducts-btn.learn-more.outline-new {
    margin-top: 0;
  }

  .button-filled.allproducts-btn.submit {
    top: auto;
    bottom: 0;
    right: 0%;
  }

  .button-filled.allproducts-btn.white-outline {
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    top: auto;
    bottom: 0;
    right: 0%;
  }

  .button-filled-text {
    color: #fff;
    text-decoration: none;
  }

  .button-filled-text.button-filled-text-mobile {
    color: #263cc0;
    font-weight: 700;
    text-decoration: underline;
  }

  .button-filled-text.button-filled-text-mobile.mobile-white-text {
    color: var(--white);
    text-decoration: none;
  }

  .button-filled-text.button-filled-text-mobile.black-text {
    color: var(--black);
    text-decoration: none;
  }

  .footer-img {
    margin-right: 0;
  }

  .footer-area-1 {
    margin-left: 40px;
  }

  .h3-white {
    font-size: 20px;
  }

  .services-white-text {
    font-size: 16px;
    line-height: 32px;
  }

  .service-text-block {
    padding-right: 0;
  }

  .service-text-area {
    padding-right: 30px;
  }

  .new-link {
    color: var(--tan);
  }

  .arrow-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .subtitle.beige {
    letter-spacing: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
  }

  .subtitle.small {
    letter-spacing: 1px;
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;
  }

  .column-6 {
    width: 100%;
  }

  .h1.hero-h1 {
    text-align: center;
  }

  .section-base {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-base.hero {
    padding-top: 40px;
  }

  .section-base._1 {
    padding-top: 10px;
    padding-bottom: 60px;
  }

  .section-base.services {
    padding-bottom: 80px;
  }

  .h2 {
    font-size: 36px;
    line-height: 40px;
  }

  .column-3,
  .column-3.col-3-mobile {
    width: 47.8%;
  }

  .column-5 {
    width: 81.2%;
  }

  .column-5.col-5-mobile,
  .column-7 {
    width: 100%;
  }

  .link-footer {
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: underline;
  }

  .link-footer.team-link {
    margin-top: 0;
    font-size: 14px;
  }

  .footer-wrapper {
    text-align: center;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }

  .footer-text-wrapper {
    margin-top: 10px;
  }

  .footer-heading {
    width: 100%;
  }

  .footer-nav.services-nav {
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 0;
  }

  .footer-nav.logo {
    flex-direction: row;
    justify-content: center;
  }

  .social-block {
    margin-bottom: 60px;
  }

  .social-block.footer {
    margin-bottom: 0;
  }

  .social-img {
    margin-left: 20px;
  }

  .column-2 {
    width: 31.1%;
  }

  .column-2.col-2-mobile,
  .column-4,
  .column-8,
  .column-9 {
    width: 100%;
  }

  .column-1 {
    width: 14.4%;
  }

  .column-11,
  .column-10 {
    width: 100%;
  }

  .melo-header-img {
    margin-bottom: 15px;
  }

  .services-wrapper {
    flex-direction: column;
  }

  .text-head-area.large,
  .text-head-area._100 {
    width: 100%;
  }

  .paragraph.hero {
    text-align: center;
  }

  .story-img {
    padding-left: 0;
  }

  .book-block {
    padding-bottom: 60px;
  }

  .welcome-text-block {
    text-align: center;
    margin-top: 20px;
  }

  .button-block {
    justify-content: center;
  }

  .services-block {
    height: auto;
    padding: 20px;
  }

  .services-text.white {
    margin-top: 10px;
  }

  .team-block {
    filter: none;
    margin-top: 20px;
    padding-top: 20px;
  }

  .team-block:hover {
    filter: none;
    transform: none;
  }

  .navigation-right {
    margin-top: -12px;
  }

  .dropdown.nav-dropdown {
    display: none;
  }

  .menu-button.w--open {
    z-index: 999;
  }

  .navigation-container {
    flex-direction: row;
  }

  .nav-link-2 {
    border-bottom-style: none;
    padding-left: 40px;
    font-size: 22px;
    line-height: 50px;
  }

  .nav-link-2.show-on-mobile {
    display: block;
  }

  .nav-link-2.show-on-mobile:hover {
    border-bottom-style: none;
  }

  .nav-link-2.mobile {
    display: block;
  }

  .navigation {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .nav-menu-2 {
    max-height: 100vh;
    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    overflow: auto;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }

  .melo-logo {
    width: 170px;
  }

  .button-filled.allproducts-btn.learn-more {
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    top: auto;
  }

  .button-filled.allproducts-btn.learn-more.outline {
    display: none;
  }

  .button-filled.allproducts-btn.learn-more.outline-new {
    margin-top: 20px;
  }

  .button-filled.allproducts-btn.submit {
    height: 38px;
    padding-top: 7px;
    padding-left: 15px;
    padding-right: 16px;
    top: auto;
  }

  .button-filled.allproducts-btn.white-outline {
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    top: auto;
  }

  .button-filled-text.button-filled-text-mobile.mobile-white-text,
  .button-filled-text.button-filled-text-mobile.black-text {
    letter-spacing: 1px;
    font-size: 0.8em;
  }

  .hair-img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .hair-img.profile {
    height: 290px;
    object-position: 50% 30%;
  }

  .hair-img.hero-story {
    object-position: 50% 50%;
  }

  .lottie-img {
    display: none;
  }

  .footer-address {
    justify-content: center;
    margin-bottom: 40px;
  }

  .html-embed {
    margin-top: 30px;
  }

  .map-wrapper {
    margin-left: 0;
  }

  .footer-area-1 {
    color: var(--white);
    margin-left: 0;
  }

  .h3-white {
    margin-top: 0;
  }

  .services-white-text {
    line-height: 22px;
  }

  .services-white-text.small.smaller {
    margin-top: 0;
    margin-bottom: 23px;
  }

  .services-white-text.line-height {
    line-height: 30px;
  }

  .service-text-block {
    padding-bottom: 10px;
  }

  .service-text-area {
    justify-content: flex-start;
  }

  .arrow-up {
    top: -5%;
  }

  .anchor-area {
    padding-top: 0;
  }

  .footer-logo-link {
    display: flex;
  }

  .footer-logo-link.w--current {
    justify-content: center;
    align-items: center;
  }

  .contact-block {
    margin-right: 40px;
  }

  .contact-wrapper {
    display: flex;
  }

  .sarah-img {
    padding-left: 0;
    padding-right: 0;
  }

  .card-melo {
    height: 210px;
  }

  .social-contact {
    margin-bottom: -35px;
  }

  .profile-img {
    padding-left: 0;
    padding-right: 0;
  }

  .arrow-mobile {
    display: block;
  }

  .join {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 479px) {
  .subtitle.small {
    font-size: 12px;
    line-height: 18px;
  }

  .container {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .column-6 {
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    font-size: 48px;
    line-height: 48px;
  }

  .h1.hero-h1 {
    line-height: 42px;
  }

  .section-base.dark-bg._0-120 {
    padding-bottom: 0;
  }

  .section-base._1 {
    padding-bottom: 20px;
  }

  .section-base.services {
    padding-bottom: 60px;
  }

  .h2 {
    font-size: 28px;
  }

  .column-3.col-3-mobile {
    width: 47.8%;
  }

  .column-7 {
    width: 100%;
  }

  .link-footer,
  .link-footer.team-link {
    font-size: 16px;
  }

  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .copyright {
    width: 100%;
    text-align: left;
    line-height: 24px;
  }

  .footer-heading {
    font-size: 28px;
    line-height: 42px;
  }

  .footer-nav.services-nav {
    flex-direction: column;
    align-items: center;
  }

  .quote {
    font-size: 28px;
    line-height: 38px;
  }

  .paragraph {
    font-size: 14px;
    line-height: 22px;
  }

  .story-img {
    padding-left: 0;
  }

  .book-block {
    background-color: var(--white);
    padding-bottom: 40px;
  }

  .services-block {
    margin-top: 20px;
    margin-right: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .services-img {
    width: 100%;
    height: 100px;
  }

  .packages-img {
    width: 100%;
  }

  .services-text {
    line-height: 29px;
  }

  .services-text.white {
    font-size: 20px;
  }

  .services-link-text.white {
    margin-right: 0;
    text-decoration: underline;
  }

  .team-block {
    padding: 10px;
  }

  .team-member-img {
    width: 100%;
    height: 90px;
  }

  .white-arrow-img {
    display: none;
  }

  .social-link-img.contact-social.small {
    margin-top: -10px;
    margin-bottom: 25px;
    margin-left: 0;
  }

  .nav-link-2 {
    padding-left: 20px;
    font-size: 20px;
    line-height: 46px;
  }

  .nav-link-2.show-on-mobile {
    padding-left: 20px;
  }

  .button-filled {
    box-shadow: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .button-filled.allproducts-btn {
    box-shadow: none;
    background-image: linear-gradient(#fff, #fff);
    margin-bottom: 10px;
    margin-right: 10px;
    text-decoration: none;
  }

  .button-filled.allproducts-btn.learn-more {
    width: 100%;
    height: 45px;
    border-radius: 2px;
    margin-right: 0;
    display: flex;
    top: 70%;
  }

  .button-filled.allproducts-btn.learn-more.outline {
    display: none;
  }

  .button-filled.allproducts-btn.submit {
    border-radius: 2px;
    margin-right: 0;
    display: block;
    top: 70%;
  }

  .button-filled.allproducts-btn.white-outline {
    width: 100%;
    height: 45px;
    border-radius: 2px;
    margin-right: 0;
    display: flex;
    top: 70%;
  }

  .button-filled-text {
    line-height: 127%;
  }

  .button-filled-text.button-filled-text-mobile {
    font-weight: 700;
    text-decoration: underline;
  }

  .hair-img.hero-story {
    object-position: 50% 50%;
  }

  .lottie-img {
    filter: invert();
  }

  .input-field {
    margin-right: 10px;
  }

  .html-embed.desktop {
    display: none;
  }

  .html-embed.mobile {
    padding: 10px;
    display: block;
  }

  .services-white-text {
    line-height: 26px;
  }

  .service-text-block {
    flex-direction: column;
  }

  .service-text-area {
    padding-right: 0;
  }

  .arrow-up {
    top: -4%;
  }

  .arrow-up.arrow-2 {
    top: -18%;
  }

  .arrow-up.arrow-3 {
    top: -16%;
  }

  .new-link {
    font-size: 16px;
    line-height: 36px;
  }

  .image-slider {
    display: none;
  }

  .contact-wrapper {
    flex-direction: column;
  }

  .sarah-img {
    padding-left: 0;
  }

  .social-contact {
    flex-direction: column;
  }

  .team-page-profile-wrapper {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .profile-img {
    padding-left: 0;
  }

  .close-btn.w--current {
    z-index: 999;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 2%;
    right: 5%;
  }
}
